<template>
  <div class="c-app bgapp flex-row align-items-center">
    <CContainer>
      <div  v-if='isMaintenance==false'> 
      <CRow class="justify-content-center">
        <CCol md="6">
          <!-- <CCardGroup> -->
            <CCard class="p-4 bg-primary">
              <CCardBody>
                <CForm>
                  <h1 class="text-white">Login</h1>
                  <p class="text-muted text-white">Input Your Account Login</p>
                  <CAlert
                    :show.sync="dismissCountDown"
                    closeButton
                    color="info"
                    fade
                  >
                    Sorry! Email & Password cant empty...
                  </CAlert>
                  <CInput
                    placeholder="Your Email"
                    v-model="username"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Your Password"
                    type="password"
                    v-model="password"
                    v-on:keyup.enter="loginNow"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="5" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        v-on:click="loginNow"
                        >Login</CButton
                      >
                    </CCol>
                    <CCol col="7" class="text-right">
                      <CButton color="link" to="/resetpassword" class="px-0"
                        >Forgot password?</CButton
                      >
                      <CButton color="link" to="/register" class="px-0"
                        >Register Your Account!</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <img
                  :src="require('../../assets/images/bitqueen.jpg')"
                  style="width: 110px; margin: 10 auto"
                />
                <h2>BlockScan</h2>
                <p>Earn extra income by staking your favorite coins.</p>
                
              </CCardBody>
            </CCard> -->
          <!-- </CCardGroup> -->
        </CCol>
      </CRow>
    </div>
    <div  v-if='isMaintenance'>
      <article class="bg-dark" style="padding: 15px;">
          <h1>We are Under Maintenance!</h1>
          <div>
              <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:queenbiz.app@gmail.com">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
              <p>&mdash; The Team</p>
          </div>
      </article>
    </div>
    </CContainer>
    <CModal title="Warning" :show.sync="myModal" size="sm">
      Failed! Please input your correct email password...
    </CModal>
    <CModal title="Warning" :show.sync="myModal2" size="sm">
      Success! You are redirecting to BlockScan...
    </CModal>
  </div>
</template>
<style scoped>
  body { text-align: center; padding: 150px; }
  h1 { font-size: 30px; }
  body { font: 18px Helvetica, sans-serif; color: #333; }
  article { display: block; text-align: left; width: 320px; margin: 0 auto; }
  a { color: #dc8100; text-decoration: none; }
  a:hover { color: #333; text-decoration: none; }
</style>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      tabs: [],
      myModal: false,
      myModal2: false,
      users: "",
      isMaintenance : false,
      dismissCountDown: 0,
      username: "",
      password: "",
      rcvr: "",
      activeTab: 1,
    };
  },  
  mounted(){
    this.$store.commit('setLogin', false);
    var histories = {
        waggered : "0.00000000",
        profits : "0.00000000",
        wins : "0",
        losses : "0",
    };
    this.$store.commit('setFaucet', histories);
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    loginNow: function (event) {
      // Simple POST request with a JSON body using axios
      const user = {
        email: this.username,
        password: this.password,
      };
      // alert(localStorage.getItem("jwt"));
      if (
        (this.username == null || this.username == "",
        this.password == null || this.password == "")
      ) {
        this.myModal = true;
      } else {
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"loginapp",
            user
          )
          .then((response) => {
            // console.log(response);
            // alert(response.data);
            // this.tabs = response.data;/
            if (response.data.status == "ok") {
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("jwt", response.data.access_token);
              // this.users = JSON.stringify(response.data.user);
              // this.$router.push("/dashboard");
              // localStorage.setItem("user", JSON.stringify(response.data.user));
              // localStorage.setItem("jwt", response.data.access_token);
              this.users = JSON.stringify(response.data.user);
              this.$store.commit('setUsers', this.users);
              this.$store.commit('setLogin', true);
              this.$router.push({ path: '/dashboard', replace: true })
              // location.href='/#/dashboard/';

              // this.myModal2 = true;
            } else {
              this.myModal = true;
            }
          });
      }
    },
  },
};
</script>
